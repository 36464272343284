<template>
    <div class="user-center-wrap">
        <div class="center-container">
            <div class="user-info" v-if="userInfo">
                <div class="avatar-box">
                    <img class="avatar" :src="userInfo.HeadImgUrl
                        ? userInfo.HeadImgUrl
                        : require('@/assets/img/avatar.png')
                        " alt />
                    <!-- <p v-if="userInfo.IDCard">未实名</p> -->
                    <img v-if="userInfo.IDCard" src="@/assets/img/isauth.png" class="auth-icon" @click="showAuthVisible" />
                    <img v-else src="@/assets/img/noauth.png" class="auth-icon" @click="showAuthVisible" />
                </div>
                <div class="info-box">
                    <div class="name">
                        {{ userInfo.NickName }}
                        <span>{{ userInfo.Phone && userInfo.Phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2") }}</span>
                        <!-- userInfo.Phone && userInfo.Phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2") -->
                    </div>

                    <div class="member">
                        <div v-if="netBarVIP && netBarVIPEndTime">
                            网吧已开通VIP特权，{{ netBarVIPEndTime.substr(0, 10) }}到期
                        </div>

                        <div v-else>
                            <div v-if="userInfo.IsNotMember">
                                已开通会员，{{ userInfo.MemberExpirationDate.split(" ")[0] }}到期
                            </div>
                            <div v-else>
                                开通会员享全场游戏免费玩
                            </div>
                        </div>
                    </div>

                    <div class="integral">
                        <span>积分：<i class="num">{{ integral }}</i></span>
                        <span v-if="scoreDesc"><i style="color: #3E87CE;font-size: 14px;">{{ scoreDesc }}积分即将到期</i></span>
                    </div>
                    <div class="integral">
                        <router-link to="/points?order_activeName=0">查看收支</router-link> / <router-link
                            to="/points?order_activeName=2">订单</router-link> / <a href="javascript:;"
                            @click="showRule">规则</a>
                    </div>
                </div>
                <div class="btn-box">
                    <el-button type="primary" size="mini" plain round @click="changeBind">{{ userInfo.Phone ? '换绑' : '绑定'
                    }}</el-button>
                    <el-button type="primary" size="mini" plain round @click="$router.push('/members')">{{
                        userInfo.IsNotMember
                        ?
                        '续费' : '开通VIP会员' }}</el-button>
                    <el-button type="primary" size="mini" plain round @click="$router.push('/signin')">领积分</el-button>
                </div>
            </div>
            <div class="grid" v-if="userInfo">
                <div class="icon">
                    <img src="@/assets/img/icon-item3.png" />
                </div>
                <div class="content">
                    <!-- <div class="title">游戏时长兑换积分</div> -->
                    <div class="title">游戏时长:{{ userInfo.GameTime }}分钟<span>(可兑换{{ parseInt(userInfo.GameTime / 12)
                    }}积分)</span></div>
                    <div class="text">每日23点统计游戏时长</div>
                    <div class="text2">
                        1小时游戏时长可兑换5积分
                    </div>
                    <el-button type="primary" size="mini" plain round @click="exchange">去兑换</el-button>
                </div>
            </div>
        </div>
        <div class="nav-box">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" router @select="handleSelect">
                <el-menu-item index="/userCenter/history">
                    <span slot="title">
                        <img
                            :src="activeIndex == '/userCenter/history' ? require('./../../assets/img/icon_a_1.png') : require('./../../assets/img/icon_a.png')" />
                        最近玩的游戏
                    </span>
                </el-menu-item>
                <el-menu-item index="/userCenter/myCollection">
                    <span slot="title">
                        <img
                            :src="activeIndex == '/userCenter/myCollection' ? require('./../../assets/img/icon_b_1.png') : require('./../../assets/img/icon_b.png')" />
                        我的收藏
                    </span>
                </el-menu-item>
                <el-menu-item index="/userCenter/myItems">
                    <span slot="title">
                        <img
                            :src="activeIndex == '/userCenter/myItems' ? require('./../../assets/img/icon_c_1.png') : require('./../../assets/img/icon_c.png')" />
                        我的游戏存档
                    </span>
                </el-menu-item>
                <el-menu-item index="/userCenter/order">
                    <span slot="title">
                        <img
                            :src="activeIndex == '/userCenter/order' ? require('./../../assets/img/icon_d_1.png') : require('./../../assets/img/icon_d.png')" />
                        会员订单
                    </span>
                </el-menu-item>
                <el-menu-item index="/userCenter/exchange">
                    <span slot="title">
                        <img
                            :src="activeIndex == '/userCenter/exchange' ? require('./../../assets/img/icon_e_1.png') : require('./../../assets/img/icon_e.png')" />
                        兑换卡券
                    </span>
                </el-menu-item>
                <!-- <el-menu-item index="/userCenter/integral">
                    <span slot="title">
                        <img
                            :src="activeIndex == '/userCenter/integral' ? require('./../../assets/img/icon_f_1.png') : require('./../../assets/img/icon_f.png')" />
                        积分订单
                    </span>
                </el-menu-item> -->
            </el-menu>
        </div>
        <div class="content-wrap">
            <router-view></router-view>
            <!-- <div>您还没有任何游戏记录，不如试试这些推荐游戏吧</div> -->
        </div>
        <!-- <div class="games-wrap">推荐游戏</div> -->

        <!-- 游戏时长兑换积分 -->
        <el-dialog title="游戏时长兑换积分" :visible.sync="dialogFormVisible" width="400px" v-if="userInfo"
            custom-class="redeem-now-dialog">
            <el-form>
                <el-form-item label="总游戏时长：" label-width="100px">
                    {{ userInfo.GameTime }}分钟
                </el-form-item>
                <el-form-item label="可兑换积分：" label-width="100px">
                    {{ parseInt(userInfo.GameTime / 12) }}积分
                    <span class="time-ps">（1小时游戏时长可兑换5积分）</span>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" v-if="userInfo.Phone" @click="redeemNow"
                    :disabled="userInfo.GameTime > 12 ? false : true">{{ userInfo.GameTime > 12 ? '立即兑换' : '游戏时长不足'
                    }}</el-button>
            </div>
        </el-dialog>

        <!-- 规则弹窗 -->
        <Dialog ref="dialog" :title="'蚂蚁特权积分规则说明'" :width="'800px'" :hasFooter="false">
            <h3>一、蚂蚁特权积分</h3>
            <!-- 去体验游戏或者在黄金版客户端购买VIP会员来获得蚂蚁特权积分 -->
            <p>用户通过登录蚂蚁特权黄金版和钻石版客户端，或者完成平台积分任务（以下简称“积分”），积分可以在蚂蚁特权客户端或者官方公众号及指定合作伙伴处兑换各种权益，参与各种活动等，具体以权益兑换及活动页面展示为准。
            </p>
            <h3>二、积分领取规则</h3>
            <p>积分发放后 ，自动发放到用户的蚂蚁特权账户上。</p>
            <h3>三、积分获取规则</h3>
            <!-- <p>1、支付购买蚂蚁特权黄金版客户端，用户登录后，购买vip会员后可以活动积分</p>
            <p>购买1天会员，获得24个积分</p>
            <p>购买3天会员，获得74个积分</p>
            <p>购买30天会员，活动720个积分</p> -->
            <p>1、体验平台游戏</p>
            <p>蚂蚁特权用户（含黄金版和钻石版）在平台上玩游戏，通过游戏时长获得对应的积分，每天23点累计当天用户游戏时间</p>
            <p>游戏时长满1个小时，可以兑换成5个积分</p>
            <p>2、签到积分</p>
            <p>单天签到获取1积分，连续签到持续增加1积分，依次类推，超过7天连续签到后都是7积分，之后断签后，再次签到回到1积分；</p>
            <p>3、每日任务积分</p>
            <p>每日单项任务完成获得2积分；</p>
            <p>4、参加电竞比赛获取10积分</p>

            <h3>四、积分有效期</h3>
            <p>用户获得的积分有效期为自获得当月起的12个自然月有效期内未使用的积分到期将自动清零，不予补发。例:您在2020年5月5日获取了10个积分，该笔积分有效期至2021年5月31日23:59:59。感谢支持!</p>

            <h3>五、其他说明</h3>
            <p>1、如用户存在违规刷积分行为（包括但不限于虚拟交易、炒信、恶意退款、刷体验时间），我们有权取消用户获取积分的资格，已获得的积分将被扣回。</p>
            <p>2、为了进一步改善用户体验，我们将不时更新积分服务的内容，积分规则也可能会随之更新，更新内容将于公布之日起生效，若您选择继续使用积分服务，表示您同意更新后的内容。若您不同意变更后的内容，您有权停止积分服务；双方协商一致的，也可另行变更相关服务和对应条款内容。如果更新的内容涉及您的主要权利或责任，我们会以公告、客户端通知或者短信等方式向您提示。您也可以随时在本页面查阅积分规则的最新版本
            </p>
        </Dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { md5 } from "@/util/crypto";
import { ExchangeIntegral } from '@/api'

export default {
    computed: {
        ...mapGetters([
            "userInfo",
            "isLogin",
            "netBarVIP",
            "netBarVIPEndTime"
            // "menuActive",
            // "netBarId",
            // "netBarName",
            // "dialogVisibleWeChat",
            // "dialogVisiblePhone",
            // "getIsAuth",
        ]),
    },
    data() {
        return {
            activeIndex: '',
            integral: 0,
            scoreDesc: 0,
            dialogFormVisible: false
        }
    },
    created() {
        this.activeIndex = this.$route.path
        if (this.isLogin && this.userInfo) {
            this.getUserScore();
        }
    },
    methods: {
        // 立即兑换
        async redeemNow() {
            let params = {
                phone: this.userInfo.Phone
            }
            let res = await ExchangeIntegral(params)
            if (res.StatusCode == 200) {
                this.$message({
                    message: "兑换成功",
                    type: "success",
                });
                // 刷新用户积分
                this.$store.dispatch("_GetUserInfo");
                this.dialogFormVisible = false;
                if (this.isLogin && this.userInfo) {
                    this.getUserScore();
                }
            }
        },
        // 游戏时长兑换积分
        exchange() {
            this.dialogFormVisible = true;
        },
        // 获取个人积分
        getUserScore() {
            if (!this.userInfo.Phone) {
                eventBus.$emit("showLoginPhone");
                return false;
            }

            let str = this.userInfo.Phone + "-THB-WEB-FOR-CLIENT-";

            let params = {
                Phone: this.userInfo.Phone,
                Token: md5(str).toUpperCase()
            }
            $.ajax({
                url: "https://score.haoyouzhushou.com/App/User/GetToken",
                type: 'POST',
                data: JSON.stringify(params),
                contentType: 'application/json',
                dataType: 'json',
                success: (res) => {
                    if (res.StatusCode == 200) {
                        let Token = res.Data.Token;

                        $.ajax({
                            url: "https://score.haoyouzhushou.com/App/User/GetUserScore",
                            type: 'POST',
                            contentType: 'application/json',
                            headers: {
                                'Authorization': 'Bearer ' + Token
                            },
                            dataType: 'json',
                            success: (resoult) => {
                                if (resoult.StatusCode == 200) {
                                    this.integral = resoult.Data.Score;
                                    this.scoreDesc = resoult.Data.ScoreDesc;
                                }
                            },
                        });
                    }
                },
            });
        },
        handleSelect(key) {
            console.log(key)
            this.activeIndex = key;
        },
        showRule() {
            this.$refs['dialog'].setDialogVisible(true);
        },
        changeBind() {
            eventBus.$emit('bindPhone')
        },
        showAuthVisible() {
            eventBus.$emit('showAuthVisible')
        }
    }
}
</script>

<style scoped lang="scss">
// ul::-webkit-scrollbar {
//     width: 2px;
//     height: 1px;
//     margin-right: 10px;
// }

// .user-center-wrap::-webkit-scrollbar-track {
//     border-radius: 10px;
//     background: #fff;
// }

// .user-center-wrap::-webkit-scrollbar-thumb {
//     border-radius: 10px;
//     background-color: #ff3636;
// }

.user-center-wrap {
    padding: 18px 20px;
    height: 680px;
    overflow: hidden;

    // overflow: auto;
    .center-container {
        display: flex;
        justify-content: space-between;

        .user-info {
            width: 606px;
            display: flex;
            align-items: center;
            padding: 16px;
            @include item_bg_col();
            border-radius: 6px;

            .avatar-box {
                padding: 0 10px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;

                .avatar {
                    width: 68px;
                    height: 68px;
                    border-radius: 34px;
                    display: block;
                    border: 1px solid #ff3636;
                    margin-bottom: 5px;
                }

                .auth-icon {
                    cursor: pointer;
                }
            }

            .info-box {
                margin: 0 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .name {
                    font-size: 20px;
                    @include font_col3();

                    span {
                        margin-left: 20px;
                    }
                }

                .member {
                    // margin: 16px 0;
                    margin-top: 16px;
                    @include font_col9();
                }

                .integral {
                    margin-top: 10px;

                    span {
                        font-size: 16px;
                        @include font_col9();
                        margin-right: 50px;

                        .num {
                            color: #ff3636;
                        }
                    }

                    a {
                        font-size: 14px;
                        // color: #3E87CE;
                        color: #ff3636;
                    }
                }
            }

            .btn-box {
                display: flex;
                flex-direction: column;
                // margin-top: -25px;

                .el-button+.el-button {
                    margin-left: 0;
                    margin-top: 5px;
                }
            }
        }

        .grid {
            // width: 400px;
            // height: 168px;
            display: flex;
            padding: 15px;
            background: #fff;
            border-radius: 6px;
            box-sizing: border-box;

            .icon {
                margin-right: 15px;
                // img {
                //     width: 70px;
                //     height: 70px;
                //     display: block;
                // }
            }

            .content {
                width: 278px;
                display: flex;
                // flex: 1;
                flex-direction: column;
                position: relative;

                .title {
                    font-size: 18px;
                    color: #333;

                    span {
                        font-size: 14px;
                        color: #666;
                    }
                }

                .text {
                    margin-top: 8px;
                    color: #666;


                    span {
                        margin-right: 10px;
                        font-size: 28px;
                        color: #333;
                    }
                }

                .text2 {
                    margin-top: 10px;
                    font-size: 13px;
                    line-height: 16px;
                    color: #ff3636;

                    i {
                        font-size: 15px;
                    }
                }

                .el-button {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }

                .el-button:hover {
                    color: #fff;
                    background: #ff3636;
                }
            }
        }
    }

    .nav-box {
        margin-top: 10px;

        .el-menu--horizontal>.el-menu-item.is-active {
            // border-top: 2px solid #ff3636;
            border-bottom: 2px solid #ff3636;
        }

        @include item_bg_col();
        border-radius: 6px;

        .el-menu {
            @include item_bg_col();

            .el-menu-item {
                @include font_col3();
            }

            ::v-deep .el-menu-item:not(.is-disabled):hover {
                background-color: transparent;
            }
        }

        .el-menu.el-menu--horizontal {
            border-bottom: none;
        }
    }

    .content-wrap {
        // height: 460px;
        margin-top: 10px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // padding: 40px 0;
        // @include item_bg_col();
        border-radius: 6px;
    }

    .games-wrap {
        margin-top: 10px;
        @include item_bg_col();
        border-radius: 6px;
    }
}
</style>

<style lang="scss">
.redeem-now-dialog {
    .el-form-item {
        margin-bottom: 0;
    }

    .time-ps {
        color: #ff3636;
    }

    .el-button--primary.is-disabled {
        background: #ccc;
        border-color: #bbb !important;
    }

    .el-button--default:hover {
        color: #ff3636;
        border-color: #ff3636;
        background: transparent;
    }
}
</style>